
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { VxeFormProps } from "vxe-table";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      title: "評論管理",
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      columns: [
        {
          field: "Name",
          title: "用戶名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Score",
          title: "評分",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.Name",
          title: "產品",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "Member.MobilePhone",
        //   title: "會員電話",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   formatter: ({ cellValue }) => (cellValue ? CloudFun.utils.formatPhone(cellValue) : "")
        // },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('comment/query', params)
          : undefined,
        queryAll: model
          ? () =>
            model.dispatch('comment/query')
          : undefined,
        save: model
          ? (params) => model.dispatch('comment/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        // { field: "Photo.Uri", title: "頭像", span: 24, slots: { default: "column-photo" } },
        { field: "Name", title: "用戶名稱", span: 12, itemRender: { name: "$input" } },
        { field: "Score", title: "評分", span: 12, itemRender: { name: "$input", props: { type: "float" } } },
        {
          field: "ProductId",
          title: "產品",
          span: 12,
          slots: { default: "column-product-id" }
        },
        // {
        //   field: "MemberId",
        //   title: "會員電話",
        //   span: 12,
        //   slots: { default: "column-member-id" }
        // },
        {
          field: "TagIds",
          title: "標籤",
          span: 24,
          slots: { default: "column-tag-ids" }
        },
        { field: "Content.Content", title: "內容", span: 24, itemRender: { name: "$textarea", props: { autosize: { minRows: 5 } } } },
      ],
      rules: {
        Name: [{ required: true }],
        Score: [{ required: true }],
        ProductId: [{ required: true }],
        "Content.Content": [{ required: true }],
      }
    };

    const productIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇產品",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("product/find", value), // eslint-disable-line
        query: params => {
          if (params.condition) {
            params.condition.and("Type", Operator.Equal, 0);
          }
          return model!.dispatch("product/query", params); // eslint-disable-line
        }
      }
    };

    // const memberIdSelectOptions: SelectBoxOptions = {
    //   showSearch: true,
    //   transfer: true,
    //   readonly: true,
    //   rowId: "Id",
    //   placeholder: "選擇會員",
    //   textField: "MobilePhone",
    //   valueField: "Id",
    //   columns: [
    //     { field: 'MobilePhone', title: '電話', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => (cellValue ? CloudFun.utils.formatPhone(cellValue) : "") }
    //   ],
    //   promises: {
    //     find: (value) => model!.dispatch("member/find", value),
    //     query: (params) => model!.dispatch("member/options", params) // eslint-disable-line
    //   },
    // }

    const tagsSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇標籤",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: true,
      showHeader: true,
      addIfNotExists: true,
      promises: {
        find: (value) => model!.dispatch("tag/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("tag/query", params), // eslint-disable-line
        insert: (row) => model!.dispatch("tag/insert", row), // eslint-disable-line
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      productIdSelectOptions,
      // memberIdSelectOptions,
      tagsSelectOptions
    };
  }
});
